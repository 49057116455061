<template>
  <div>
    <FormGroup
      v-if="showTypeOptions"
      :label="typeOptionsLabel"
      :rules="['requiredOption']"
      key="type"
    >
      <InputOptions
        v-model="party.type"
        :options="optionsPartyType"
      />
    </FormGroup>

    <FormGroup
      v-if="party.type !== 'COMPANY'"
      label="Meno"
      :rules="['required']"
      key="firstName"
    >
      <InputText
        v-model="party.firstName"
        autocomplete="given-name"
      />
    </FormGroup>

    <FormGroup
      v-if="party.type !== 'COMPANY'"
      label="Priezvisko"
      :rules="['required']"
      key="lastName"
    >
      <InputText
        v-model="party.lastName"
        autocomplete="family-name"
      />
    </FormGroup>

    <FormGroup
      v-if="showTitleBeforeName && party.type !== 'COMPANY'"
      label="Titul pred menom"
      key="titleBeforeName"
    >
      <InputText
        v-model="party.titleBeforeName"
        autocomplete="honorific-prefix"
      />
    </FormGroup>

    <div
      v-if="!showTitleBeforeName && party.type !== 'COMPANY'"
      :style="{ marginBottom: '30px' }"
      key="showTitleBeforeName"
    >
      <BaseButton
        size="small"
        variant="secondary"
        @click="showTitleBeforeName = true"
      >
        Pridať titul
      </BaseButton>
    </div>

    <FormGroup
      v-if="party.type !== 'PERSON'"
      :label="party.type === 'SELFEMPLOYED' ? 'Obchodné meno' : 'Názov firmy'"
      :rules="['required']"
      key="businessName"
    >
      <InputText
        v-model="party.businessName"
        autocomplete="organization"
      />
    </FormGroup>

    <FormGroup
      v-if="party.type === 'PERSON'"
      label="Rodné číslo"
      tooltipText="Vaše osobné údaje sú prenášané na server zabezpečeným spojením."
      helpText="Zadajte v tvare 740314/0910"
      :rules="[
        'required',
        'nationalIdentificationNumber',
        nationalIdentificationNumberMatchesYearRule({
          compare: currentBirthYear,
        }),
        normalizedNotInRule({
          compare: unavailableNationalIdentificationNumbers,
          message: 'Toto rodné číslo ste už zadali. Musíte zadať rodné číslo inej osoby.',
        }),
      ]"
      :afterMessage="nationalIdentificationNumberAfterMessage"
      key="nationalIdentificationNumber"
    >
      <InputNationalIdentificationNumber
        v-model="party.nationalIdentificationNumber"
      />
    </FormGroup>

    <FormGroup
      v-if="party.type !== 'PERSON'"
      label="IČO"
      :rules="[
        'required',
        'companyRegistrationNumber',
        normalizedNotInRule({
          compare: unavailableCompanyRegistrationNumbers,
          message: 'Toto IČO ste už zadali. Musíte zadať IČO iného subjektu.',
        }),
      ]"
    >
      <InputCompanyRegistrationNumber
        v-model="party.companyRegistrationNumber"
      />
    </FormGroup>

    <div
      v-if="party.type !== 'COMPANY'"
      key="documentNumber"
    >
      <FormGroup
        label="Typ dokladu"
        v-if="showDocumentTypeOptions"
        ref="documentType"
        key="documentType"
        :rules="['requiredOption']"
      >
        <InputOptions
          v-model="party.documentType"
          :options="optionsDocumentType"
        />
      </FormGroup>

      <FormGroup
        :label="showDocumentTypeOptions ? 'Číslo dokladu' : 'Číslo občianskeho preukazu'"
        tooltipText="Vaše osobné údaje sú prenášané na server zabezpečeným spojením. Tento údaj
        potrebujeme za účelom uzavretia poistnej zmluvy."
        :helpText="!showDocumentTypeOptions ? 'Zadajte v tvare AB123456' : null"
        :rules="['required', !showDocumentTypeOptions ? 'idCardNumber' : null ]"
      >
        <InputText
          v-model="party.documentNumber"
          autocapitalize="characters"
        />
      </FormGroup>

      <BaseButton
        v-if="!showDocumentTypeOptions"
        size="small"
        variant="secondary"
        :style="{ marginBottom: '30px' }"
        @click="displayDocumentTypeOptions()"
      >
        Iný doklad
      </BaseButton>
    </div>

    <FormGroup
      v-if="isNationalitiesLoaded"
      label="Štátna príslušnosť"
      :rules="['requiredOption']"
      key="nationality"
    >
      <InputSelect
        v-model="party.nationality"
        :options="optionsNationality"
        autocomplete="country"
      />
    </FormGroup>
  </div>
</template>

<script>
import { differenceInYears } from 'date-fns';
import transformCodelist from '@gds/util/transformCodelist';
import scrollToElement from '@gds/util/scrollToElement';

import FormGroup from '@gds/components/FormGroup';
import InputText from '@gds/components/InputText';
import InputSelect from '@gds/components/InputSelect';
import InputOptions from '@gds/components/InputOptions';
import BaseButton from '@gds/components/BaseButton';

import InputNationalIdentificationNumber from './InputNationalIdentificationNumber.vue';
import InputCompanyRegistrationNumber from './InputCompanyRegistrationNumber.vue';

import nationalIdentificationNumberMatchesYearRule from './util/nationalIdentificationNumberMatchesYearRule';
import normalizedNotInRule from './util/normalizedNotInRule';
import getBirthDateFromNationalIdentificationNumber from './util/getBirthDateFromNationalIdentificationNumber';
import GET_NATIONALITIES from './graphql/GetNationalities.gql';

export default {
  name: 'InputParty',
  components: {
    FormGroup,
    InputText,
    InputSelect,
    InputOptions,
    BaseButton,
    InputNationalIdentificationNumber,
    InputCompanyRegistrationNumber,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        type: null,
        firstName: '',
        lastName: '',
        titleBeforeName: '',
        businessName: '',
        nationalIdentificationNumber: '',
        companyRegistrationNumber: '',
        documentType: 'ID',
        documentNumber: '',
        nationality: 'SVK',
      }),
    },
    showTypeOptions: {
      type: Boolean,
      default: true,
    },
    typeOptionsLabel: {
      type: String,
      default: 'Vaša právna forma',
    },
    currentBirthYear: {
      type: String,
    },
    unavailableNationalIdentificationNumbers: {
      type: Array,
      default: () => [],
    },
    unavailableCompanyRegistrationNumbers: {
      type: Array,
      default: () => [],
    },
    specialRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      party: { ...this.value },
      showTitleBeforeName: !!this.value.titleBeforeName,
      showDocumentTypeOptions: this.value.documentType !== 'ID',
      nationalIdentificationNumberMatchesYearRule,
      normalizedNotInRule,
      isNationalitiesLoaded: false,
    };
  },
  apollo: {
    nationalitiesCodelist: {
      query: GET_NATIONALITIES,
      update(data) {
        this.isNationalitiesLoaded = true;
        return data.nationalitiesCodelist;
      },
    },
  },
  computed: {
    optionsNationality() {
      if (this.nationalitiesCodelist) {
        const sorted = [...this.nationalitiesCodelist];
        sorted.sort((a, b) => a.value.localeCompare(b.value));
        const transformed = transformCodelist(sorted);
        return [
          { value: 'SVK', label: 'Slovensko' },
          { value: null, label: '---', disabled: true },
          ...transformed,
        ];
      }
      return [
        { value: null, label: 'Prosím, vyberte…', disabled: true },
      ];
    },
    optionsPartyType: () => [
      { value: 'PERSON', label: 'Osoba' },
      { value: 'SELFEMPLOYED', label: 'SZČO' },
      { value: 'COMPANY', label: 'Firma' },
    ],
    optionsDocumentType: () => [
      { value: 'ID', label: 'Občiansky preukaz' },
      { value: 'PASSPORT', label: 'Cestovný pas' },
    ],
    nationalIdentificationNumberAfterMessage() {
      const { nationalIdentificationNumber } = this.value;
      if (
        this.specialRules.includes('OWNER_YOUNGER_15_YEARS')
      ) {
        const birthDate = getBirthDateFromNationalIdentificationNumber(
          nationalIdentificationNumber,
        );
        const today = new Date();
        const isYoungerThan15 = differenceInYears(today, birthDate) < 15;
        if (birthDate && isYoungerThan15) {
          return 'Pozor, ak je vlastník mladší ako 15 rokov, držiteľ musí byť jeho zákonný zástupca.';
        }
      }
      return null;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (JSON.stringify(this.party) !== JSON.stringify(newValue)) {
          this.party = { ...newValue };
        }
      },
      deep: true,
    },
    party: {
      handler(newValue) {
        this.$emit('input', { ...newValue });
      },
      deep: true,
    },
    'party.documentNumber': {
      handler(newValue) {
        const transformed = newValue.toUpperCase();
        if (transformed !== newValue) {
          this.party.documentNumber = transformed;
        }
      },
    },
  },
  methods: {
    displayDocumentTypeOptions() {
      this.party.documentType = 'PASSPORT';
      this.showDocumentTypeOptions = true;
      this.$nextTick(() => {
        scrollToElement({
          element: this.$refs.documentType.$el,
        });
      });
    },
  },
};
</script>
