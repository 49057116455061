<template>
  <div
    class="input-autocomplete"
    @keydown="handleKeyDown"
  >
    <InputText
      ref="input"
      :value="value"
      :inputmode="inputmode"
      autocomplete="off"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @blur="$emit('blur', $event)"
    />
    <div
      v-if="isFocused && options.length"
      class="options-list"
    >
      <button
        v-for="(option, i) in options"
        :key="i"
        :class="{
          'option-item': true,
          '-focus': i === focusIndex
        }"
        tabindex="-1"
        @click="submit(option)"
      >
        {{ optionsFormatter(option) }}
      </button>
    </div>
  </div>
</template>

<script>
import './InputAutocomplete.scss';
import InputText from '@gds/components/InputText';

// Inspired by https://github.com/trevoreyre/autocomplete
export default {
  name: 'InputAutocomplete',
  data() {
    return {
      isFocused: false,
      focusIndex: -1,
      options: [],
    };
  },
  components: {
    InputText,
  },
  props: {
    value: {
      type: String,
    },
    inputmode: {
      type: String,
    },
    // Search function that provides the options, can be sync/async
    optionsProvider: {
      type: Function,
      required: true,
    },
    // Result formatter function
    optionsFormatter: {
      type: Function,
      default: (value) => value,
    },
  },
  methods: {
    updateOptions() {
      const options = this.optionsProvider(this.value);
      Promise.resolve(options).then((resolvedOptions) => {
        this.options = resolvedOptions;
      });
    },
    submit(value) {
      this.$emit('input', this.optionsFormatter(value));
      this.$emit('submit', value);
      this.isFocused = false;
    },
    handleComponentFocus() {
      this.isFocused = true;
    },
    handleDocumentFocus(event) {
      if (this.$el.contains(event.target)) {
        return;
      }
      this.isFocused = false;
    },
    handleKeyDown(event) {
      const { key } = event;

      switch (key) {
        case 'Up': // IE/Edge
        case 'Down': // IE/Edge
        case 'ArrowUp':
        case 'ArrowDown': {
          this.focusIndex = (key === 'ArrowUp' || key === 'Up')
            ? this.focusIndex - 1
            : this.focusIndex + 1;
          event.preventDefault();
          break;
        }
        case 'Enter': {
          const selectedOption = this.options[this.focusIndex];
          this.submit(selectedOption);
          break;
        }
        case 'Esc': // IE/Edge
        case 'Escape': {
          this.$refs.input.$el.blur();
          this.isFocused = false;
          break;
        }
        default:
      }
    },
  },
  watch: {
    isFocused() {
      this.focusIndex = -1;
    },
    value() {
      this.focusIndex = -1;
      this.updateOptions();
    },
    focusIndex(newValue) {
      if (newValue >= this.options.length) {
        this.focusIndex = -1;
      }
    },
  },
  mounted() {
    this.$refs.input.$el.addEventListener('focus', this.handleComponentFocus);
    this.$refs.input.$el.addEventListener('click', this.handleComponentFocus);
    document.addEventListener('click', this.handleDocumentFocus);
  },
  beforeDestroy() {
    this.$refs.input.$el.removeEventListener('focus', this.handleComponentFocus);
    this.$refs.input.$el.removeEventListener('click', this.handleComponentFocus);
    document.removeEventListener('click', this.handleDocumentFocus);
  },
};
</script>
