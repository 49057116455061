<template>
  <InputText
    v-model="companyRegistrationNumber"
    inputmode="numeric"
  />
</template>

<script>
import InputText from '@gds/components/InputText';

const removeWhitespace = (value) => value.replace(/\s+/g, '');

export default {
  name: 'InputCompanyRegistrationNumber',
  components: {
    InputText,
  },
  props: {
    value: String,
  },
  data() {
    return {
      companyRegistrationNumber: this.value,
    };
  },
  methods: {
    normalizeValue() {
      const newCrn = removeWhitespace(this.companyRegistrationNumber);
      const crnRegex = /^\d{6,8}$/;
      if (crnRegex.test(newCrn)) {
        this.companyRegistrationNumber = newCrn;
        this.$emit('input', this.companyRegistrationNumber);
      }
    },
  },
  beforeDestroy() {
    this.normalizeValue();
  },
  watch: {
    companyRegistrationNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue);
      }
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.companyRegistrationNumber = newValue;
      }
    },
  },
};
</script>
