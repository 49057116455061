const normalizeValue = (value) => value.replace('/', '');

const normalizedNotInRule = (props = {}) => ({
  message: () => props.message || 'Túto hodnotu nie je možné zadať.',
  pass: (value) => {
    if (!value || !props.compare) return true;

    const normalizedUnavailable = props.compare.map((u) => normalizeValue(u));
    const normalizedValue = normalizeValue(value);

    return !normalizedUnavailable.includes(normalizedValue);
  },
});

export default normalizedNotInRule;
