<template>
  <div>
    <FormGroup
      label="PSČ"
      :rules="[
        'required', 'postalCode'
      ]"
    >
      <InputAutocomplete
        v-model.trim="address.postalCode"
        :optionsProvider="getPostalCodeOptions"
        :optionsFormatter="postalCodeFormatter"
        inputmode="numeric"
        @submit="address.postalCode = $event.postalCode; address.town = $event.town"
      />
    </FormGroup>
    <FormGroup
      label="Obec"
      :rules="[
        'required',
      ]"
    >
      <InputAutocomplete
        v-model="address.town"
        :optionsProvider="getTownOptions"
        :optionsFormatter="townFormatter"
        @submit="address.town = $event.town"
      />
    </FormGroup>
    <FormGroup
      label="Ulica"
      :rules="[
        'required',
      ]"
    >
      <InputAutocomplete
        v-model="address.street"
        :optionsProvider="getStreetOptions"
        :optionsFormatter="streetFormatter"
        @submit="address.street = $event.street"
      />
    </FormGroup>
    <FormGroup
      label="Číslo domu"
      :rules="[
        'required', 'streetNumber'
      ]"
    >
      <InputText
        v-model="address.streetNumber"
        autocomplete="off"
      />
    </FormGroup>

  </div>
</template>

<script>
import FormGroup from '@gds/components/FormGroup';
import InputAutocomplete from '@gds/components/InputAutocomplete';
import InputText from '@gds/components/InputText';

import POSTAL_CODE_LOOKUP from './graphql/PostalCodeLookup.gql';
import TOWN_LOOKUP from './graphql/TownLookup.gql';
import STREET_LOOKUP from './graphql/StreetLookup.gql';

const removeDuplicates = (arr) => (
  arr.filter((item, index, self) => (
    index === self.findIndex((item2) => (
      JSON.stringify(item) === JSON.stringify(item2)
    ))
  ))
);

export default {
  name: 'InputAddress',
  components: {
    FormGroup,
    InputAutocomplete,
    InputText,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        postalCode: '',
        town: '',
        street: '',
        streetNumber: '',
      }),
    },
  },
  data() {
    return {
      address: { ...this.value },
    };
  },
  methods: {
    async getPostalCodeOptions() {
      if (!this.address.postalCode || this.address.postalCode.length < 2) {
        return [];
      }
      const response = await this.$apollo.query({
        query: POSTAL_CODE_LOOKUP,
        variables: {
          address: {
            postalCode: this.address.postalCode,
            town: this.address.town,
          },
        },
      });
      if (response.data.postalCodeLookup) {
        const items = [...response.data.postalCodeLookup];
        items.sort((a, b) => a.postalCode.localeCompare(b.postalCode));
        const itemsDeduped = removeDuplicates(items);
        return itemsDeduped;
      }
      return [];
    },
    async getTownOptions() {
      if (
        !this.address.postalCode
        && (!this.address.town || this.address.town.length < 2)
      ) {
        return [];
      }
      const response = await this.$apollo.query({
        query: TOWN_LOOKUP,
        variables: {
          address: {
            postalCode: this.address.postalCode,
            town: this.address.town,
          },
        },
      });
      if (response.data.townLookup) {
        return removeDuplicates(response.data.townLookup);
      }
      return [];
    },
    async getStreetOptions() {
      if (!this.address.street) {
        return [];
      }
      const response = await this.$apollo.query({
        query: STREET_LOOKUP,
        variables: {
          address: {
            postalCode: this.address.postalCode,
            town: this.address.town,
            street: this.address.street,
          },
        },
      });
      if (response.data.streetLookup) {
        return response.data.streetLookup;
      }
      return [];
    },
  },
  computed: {
    postalCodeFormatter: () => (item) => `${item.postalCode} - ${item.town}`,
    townFormatter: () => (item) => item.town,
    streetFormatter: () => (item) => item.street,
  },
  watch: {
    address: {
      handler(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    },
  },
};
</script>
