import capitalize from './capitalize';

const transformCodelist = (codelist, options = {
  makeCapitalize: true,
  toNumber: false,
}) => {
  codelist.sort((a, b) => (
    a.value.localeCompare(b.value)
  ));
  return codelist.map((item) => {
    let newValue = item.key;
    let newLabel = item.value;
    if (options.makeCapitalize) {
      newLabel = capitalize(newLabel);
    }
    if (options.toNumber) {
      newValue = Number(newValue);
    }
    return {
      value: newValue,
      label: newLabel,
    };
  });
};

export default transformCodelist;
