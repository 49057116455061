import getBirthDateFromNationalIdentificationNumber from './getBirthDateFromNationalIdentificationNumber';

const nationalIdentificationNumberMatchesYearRule = (props = {}) => ({
  message: () => props.message || 'Rok narodenia zistený z rodného čísla sa nezhoduje s rokom narodenia držiteľa, ktorý ste zadali predtým.',
  pass: (value) => {
    if (!value || !props.compare) return true;
    const birthDate = getBirthDateFromNationalIdentificationNumber(value);
    if (!birthDate) return true;
    const birthYear = birthDate.getFullYear();
    return birthYear === Number(props.compare);
  },
});

export default nationalIdentificationNumberMatchesYearRule;
