<template>
  <InputText
    v-model="nationalIdentificationNumber"
  />
</template>

<script>
import InputText from '@gds/components/InputText';

export default {
  name: 'InputNationalIdentificationNumber',
  components: {
    InputText,
  },
  props: {
    value: String,
  },
  data() {
    return {
      nationalIdentificationNumber: this.value,
    };
  },
  methods: {
    normalizeValue() {
      const originalNin = this.nationalIdentificationNumber;
      const ninRegex = /^\d{9,10}$/;
      if (ninRegex.test(originalNin)) {
        const newNin = `${originalNin.slice(0, 6)}/${originalNin.slice(6)}`;
        this.nationalIdentificationNumber = newNin;
        this.$emit('input', this.nationalIdentificationNumber);
      }
    },
  },
  beforeDestroy() {
    this.normalizeValue();
  },
  watch: {
    nationalIdentificationNumber(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue);
      }
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.nationalIdentificationNumber = newValue;
      }
    },
  },
};
</script>
